<script setup>
  import { notify } from 'notiwind'
  import { askLogin } from '@/utils/ask-login'

  const { job } = defineProps({
    job: { required: true, type: Object }
  })

  const { user, fetchUser } = useUser()
  const { create, delete: del } = useStrapi()
  const route = useRoute()

  // Favorite local state
  const favorite = ref(user.value?.favorites?.find(favorite => favorite.job.id === job.id))

  const toggleFavorite = {
    onClick: async job => {
      if (toggleFavorite.toggling.value) return
      toggleFavorite.toggling.value = true
      try {
        if (favorite.value) {
          await del('favorites', favorite.value.id)
          favorite.value = null
        } else {
          const newFavorite = await create('favorites', { user: user.value.id, job: job.id })
          favorite.value = newFavorite.data
        }
      } catch (error) {
        notify({
          group: 'main',
          type: 'danger',
          title: 'Une erreur s’est produite',
          text: 'Si le problème persiste, merci de contacter notre équipe'
        })
      } finally {
        await fetchUser()
      }
      toggleFavorite.toggling.value = false
    },
    toggling: ref(false)
  }
</script>

<template>
  <NuxtLink class="group flex flex-col rounded-xl drop-shadow-md duration-300 hover:drop-shadow-xl md:flex-row" :to="`/jobs/${job.slug}`">
    <NuxtImg
      class="rounded-t-xl object-cover md:h-[200px] md:w-[270px] md:rounded-xl"
      :src="
        job.header?.cover?.url || job.employer?.cover?.url
          ? useStrapiMedia(job.header?.cover?.url || job.employer?.cover?.url)
          : '/images/no-image.webp'
      "
      :alt="job.title"
    />
    <div class="relative w-full rounded-b-xl bg-white p-4 pl-6 duration-300 md:my-4 md:rounded-l-none md:rounded-r-xl">
      <div class="flex items-center space-x-2">
        <NuxtImg
          class="size-8 rounded-full bg-zinc-300 bg-cover bg-center"
          :src="job.employer?.logo?.url ? useStrapiMedia(job.employer?.logo?.url) : '/images/no-image.webp'"
        />
        <div class="line-clamp-1" v-text="job.employer?.name" />
      </div>
      <Icon
        class="absolute bottom-6 right-6 scale-150 transition-all duration-300 md:right-4 md:top-6 md:scale-100 md:hover:scale-105"
        v-if="!user || user.candidate"
        @click.prevent="user ? toggleFavorite.onClick(job) : askLogin(route.fullPath)"
        name="heart"
        :type="favorite ? 'solid' : 'regular'"
      ></Icon>
      <div class="line-clamp-2 pt-2 font-special text-xl font-bold md:line-clamp-1 md:text-lg" v-text="job.title" />
      <div class="flex flex-col justify-between space-y-2 pt-4 md:flex-row md:space-y-0">
        <div class="flex items-center space-x-3">
          <div class="flex items-center space-x-1.5" v-if="job.locations?.length && job.locations[0].city">
            <Icon name="location-dot" />
            <span>{{ job.locations[0].city }}</span>
          </div>
          <div class="rounded-lg bg-zinc-200 p-1" v-if="job.type !== 'public'" v-text="convertJobType(job.type)" />
        </div>
        <p class="md:ml-auto">{{ $dayjs(job.validatedAt).fromNow() }}</p>
      </div>
    </div>
  </NuxtLink>
</template>
